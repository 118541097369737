import React, { useState, useEffect } from 'react';
import { Device } from '@twilio/voice-sdk';
let currentCall = null


const CallPage = () => {
  const [device, setDevice] = useState(null);
  const [status, setStatus] = useState('Initializing...');
  const [inCall, setInCall] = useState(false);
  const [identity, setIdentity] = useState('');
  const [token, setToken] = useState('');
  const [next, setNext] = useState('');
  const [ringing, setRinging] = useState(false);
  const serverUrl = "https://server.hazran.online/";
  // const serverUrl = "https://66fe-37-60-41-10.ngrok-free.app/";
  // const serverUrl = "http://localhost:5000//";
  
useEffect(() => {
  // הגדרת רקע לכל העמוד
  document.body.style.backgroundImage = 'url("/background.jpg")';
  document.body.style.backgroundSize = 'cover';
  document.body.style.backgroundPosition = 'center';

  // יצירת הקישור דינמית
  const link = document.createElement('a');
  link.href = 'https://hazran.online';
  link.textContent = ' עזרן - נתב בינה מלאכותית טלפוני hazran.online';
  link.style.display = 'block';
  link.style.textAlign = 'center';
  link.style.marginTop = '20px';
  link.style.color = '#ffffff';
  link.style.textDecoration = 'none';
  link.style.fontSize = '14px';
  link.target = '_blank';
  link.rel = 'noopener noreferrer';

  // הוספת הקישור לגוף הדף
  document.body.appendChild(link);

  // פונקציה לניקוי - מחזירה את הרקע למצבו הרגיל ומסירה את הקישור בעת ניטרול הקומפוננטה
  return () => {
    document.body.style.backgroundImage = '';
    document.body.style.backgroundSize = '';
    document.body.style.backgroundPosition = '';
    document.body.removeChild(link);
  };
}, []);



// סגנון עבור האלמנט העליון
const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'auto',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  borderRadius: '10px',
  padding: '40px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  maxWidth: '600px',
  margin: '50px auto',
  gap: '20px',
};

const buttonStyle = {
  padding: '10px 20px',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  backgroundColor: '#007BFF',
  color: 'white',
  fontSize: '16px',
  width: '80%',
  textAlign: 'center',
  margin: '10px auto',
};
  useEffect(() => {
    const userIdentity = prompt('אנא הכנס משתמש:');
    if (!userIdentity) {
      alert('לא ניתן להתחיל שיחה ללא שם משתמש.');
      setStatus('User identity is required');
      return;
    }
    setIdentity(userIdentity);

    const setupTwilioDevice = async () => {
      try {
        const response = await fetch(`${serverUrl}site_call_management/get_token?identity=${userIdentity}`); // קבל טוקן מהשרת
        const data = await response.json();
        setToken(data.token)
        const newDevice = new Device(data.token);
        newDevice.register()
        setDevice(newDevice)
        console.log('Device state:', newDevice.state); 
        console.log('Device created:', newDevice)
        newDevice.addListener('registered', (newDevice) => {
          setStatus('Ready to make calls')});
        newDevice.on('error', (error) => setStatus(`Error: ${error.message}`));
        newDevice.on('disconnect', () => {
          setStatus('Call disconnected');
          setInCall(false);
        });

        // setDevice(newDevice);
      } catch (error) {
        setStatus(`Error initializing Twilio Device: ${error.message}`);
      }
    };

    setupTwilioDevice();
  }, []);

  // התחלת שיחה
  const makeCall = async () => {
    try{
    if (!device) {
      setStatus('Twilio Device is not ready');
      return;
    }
    if (currentCall){
      currentCall.disconnect()
    }
    alert("אני מתחיל שיחה, אנא אשר!")
    const connection = await  device.connect({ params: {"token":token, "identity":identity, "call_type":next} }); // התחלת שיחה ללא מספר טלפון
    currentCall = connection
    if (connection && typeof connection.on === 'function') {
      connection.on('ringing', () => {
        setStatus('Ringing...')
        setRinging(true)});
      connection.on('accept', () => {
        setStatus('Call in progress');
        setInCall(true);
        setRinging(false)
      });
      connection.on('disconnect', () => {
        setStatus('Call ended');
        setInCall(false);
        setRinging(false)
      });
    } else {
      setStatus('Failed to attach event listeners to connection.');
      setRinging(false)
    }
  }

catch(error) {
  // טיפול בשגיאה
  console.error("An error occurred:", error);
}}
const handleActionAndContinue = async (action) => {
    try {
      const response = await fetch(`${serverUrl}site_call_management/marketer_connection/${action}/${identity}`, {
        // const response = await fetch(`${serverUrl}site_call_management/marketer_connection/move_the_customer_to_auto_registration_and_close_marketer_call/${identity}`, {
        method: 'POST',
      });
      if (response.ok) {
      setInCall(false);
      // makeCall()
      } else {
        console.error(`${action}: failed with status:`, response.status);
      }
    } catch (error) {
      console.error(`${action}: failed with error:`, error);
    }
  }
  const handleActionAndStop = async (action) => {
    setNext('')
  try {
    const response = await fetch(`${serverUrl}site_call_management/marketer_connection/${action}/${identity}`, {
      // const response = await fetch(`${serverUrl}site_call_management/marketer_connection/move_the_customer_to_auto_registration_and_close_marketer_call/${identity}`, {
      method: 'POST',
    });
    if (response.ok) {
    setInCall(false);
    } else {
      console.error(`${action}: failed with status:`, response.status);
    }
  } catch (error) {
    console.error(`${action}: failed with error:`, error);
  }
}

  // ניתוק שיחה
  const hangUp = () => {
    if (currentCall) {
      currentCall.disconnect();
      currentCall = null;
      setNext('')
      setStatus('Call disconnected');
      setInCall(false);
      
      // alert("השיחה נותקה")
    }
  };

  useEffect(()=>{
    !inCall && identity && next && makeCall()
  },[inCall, next] )
  
  return (
    <div style={containerStyle}>
      <h1>מערכת שיחות רישום לנציגי השרות של זירת הנציגים של עזרן</h1>
      <p>Status: {status}</p>

      {!inCall && (
        <div>
        <button style={buttonStyle} onClick={()=>{
          setNext('before_connection')
          }} disabled={!device}>
          התחל שיחות הרשמה חדשות לנציגי השרות (עסקים חדשים)
        </button>
        <button style={buttonStyle} onClick={()=>{
          setNext('waiting_for_human_representative_return')
          }} disabled={!device}>
        התחל שיחות המשך להרשמה לנציגי השרות (בקשו שנחזור)
        </button>
        <button style={buttonStyle} onClick={()=>{
          setNext('connection_failed')
          }} disabled={!device}>
        התחל שיחות חוזרות להרשמה לנציגי השרות (שיחות שלא נענו)
        </button>
        </div>
      )}
      {ringing && <button style={buttonStyle} onClick={hangUp}>
            נתק את השיחה
          </button>}
      {inCall && (
        <div>
          <button style={buttonStyle} onClick={() => handleActionAndContinue("move_the_customer_to_auto_registration_and_close_marketer_call")}>
            העבר את בעל העסק <b> לרישום אוטומטי </b> והמשך לבעל העסק הבא
          </button>

          <button style={buttonStyle} onClick={() => handleActionAndContinue("report_request_for_late_marketer_callback_and_close_marketer_call")}>
            בעל העסק מבקש <b>שאחזור אליו יותר מאוחר</b> , נתק עכשיו את השיחה ועבור לבעל עסק אחר
          </button>
          <button style={buttonStyle} onClick={() => handleActionAndContinue("report_request_for_late_bsr_ar_callback_and_close_marketer_call")}>
            בעל העסק מבקש לעבור <b> לרישום אוטומטי  אבל יותר מאוחר</b> , נתק עכשיו את השיחה ועבור לבעל עסק אחר
          </button>

          <button style={buttonStyle} onClick={() => handleActionAndContinue('report_not_interested_and_close_marketer_call')}>
            בעל העסק אינו מעוניין \ כבר רשום \ הגעתי לנמען<b> לא רלוונטי</b>  ועבור לבעל עסק אחר
          </button>


          <br/><br/><br/>

          <button style={buttonStyle} onClick={() => handleActionAndStop("move_the_customer_to_auto_registration_and_close_marketer_call")}>
          העבר את בעל העסק <b> לרישום אוטומטי </b> וסיים את ניהול השיחות
          </button>
          
          
          <button style={buttonStyle} onClick={() => handleActionAndStop("report_request_for_late_marketer_callback_and_close_marketer_call")}>
            בעל העסק מבקש שאחזור אליו יותר מאוחר, נתק עכשיו את השיחה וסיים את ניהול השיחות
          </button>
          
          
          <button style={buttonStyle} onClick={() => handleActionAndStop("report_request_for_late_bsr_ar_callback_and_close_marketer_call")}>
            בעל העסק מבקש לעבור <b> לרישום אוטומטי </b> אבל יותר מאוחר, נתק עכשיו את השיחה וסיים את ניהול השיחות
          </button>
           
          <button style={buttonStyle} onClick={() => handleActionAndStop('report_not_interested_and_close_marketer_call')}>
            בעל העסק אינו מעוניין \ כבר רשום \ הגעתי לנמען לא רלוונטי וסיים את ניהול השיחות
          </button>

          <br/><br/>


          <button style={buttonStyle} onClick={hangUp}>
            נתק את השיחה - אין להתשמש בניתוק זה אלא לפני שבעל העסק ענה
          </button>


          
        </div>
      )}
    </div>
  );
};

export default CallPage;

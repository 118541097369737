import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ResumeInsertCode from './pages/resume/InsertCode';
import ResumeDisplayer from './pages/resume/displayer';
import HomePage from './pages/HomePage';
import CrudeJobEntry from './pages/CrudeJobEntry';
import JobEntry from './pages/JobEntry';
import AdvertisingPrices from './pages/Advertising/AdvertisingPrices'
import ThankYouPage from './pages/Advertising/ThankYou'
import VoiceAiRouterForBusiness from './pages/VoiceAiRouterForBusiness/VoiceAiRouterForBusiness'
import EditVoiceAiRouterForBusiness from './pages/VoiceAiRouterForBusiness/EditVoiceAiRouterForBusiness'
import CallTracking from './pages/VoiceAiRouterForBusiness/callTracking'
import GetCredit from './pages/getCredit/getCredit'
import PaymentManagement from './pages/getCredit/paymentManagement'

import VoiceAiRouterMenu from './pages/VoiceAiRouterForBusiness/menu'
import FreeArenaMenu from './pages/FreeArena/menu';
import FreeArenaRegistration from './pages/FreeArena/registration';
import CompetitionRegistration from './pages/FreeArena/competitionRegistration';
import FreeArenaContents from './pages/FreeArena/contents';
import CallComponent from './pages/marketerManagement'


function AppRouter() {
  return (
    <Router>
      <Routes>
        
        <Route path="/resume/insert_code" element={<ResumeInsertCode />} />
        <Route path="/resume/displayer/:localId" element={<ResumeDisplayer />} />
        <Route path="/jobs/crude_job_entry" element={<CrudeJobEntry />} />
        <Route path="/jobs/job_entry" element={<JobEntry />} />
        <Route path="/advertising/advertising_prices" element={<AdvertisingPrices />} />
        <Route path="/VoiceAiRouterForBusiness/menu" element={<VoiceAiRouterMenu />} />
        <Route path="/VoiceAiRouterForBusiness/insert" element={<VoiceAiRouterForBusiness />} />
        <Route path="/VoiceAiRouterForBusiness/update" element={<EditVoiceAiRouterForBusiness />} />
        <Route path="/VoiceAiRouterForBusiness/callTracking" element={<CallTracking />} />
        <Route path="/FreeArena/menu" element={<FreeArenaMenu />} />
        <Route path="/FreeArena/registration" element={<FreeArenaRegistration />} />
        <Route path="/FreeArena/Competition/Registration" element={<CompetitionRegistration />} />
        {/* <Route path="/FreeArena/Instructions" element={<FreeArenaInstructions />} /> */}
        <Route path="/FreeArena/content/:content" element={<FreeArenaContents />} />
        <Route path="/marketerManagement" element={<CallComponent />} />

        <Route path="/getCredit" element={<GetCredit />} />
        <Route path="/paymentManagement" element={<PaymentManagement />} />
        <Route path="/ThankYouPage" element={<ThankYouPage />} />
        <Route path="/*"  element={<HomePage />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
